<template>
  <nav-header :title="title"></nav-header>
  <div class="container">
    <div
      v-if="couldNotUpdateRankingSetting"
      class="alert alert-warning"
      role="alert"
    >
      Kunne ikke opdatere indstilling for visning på rangliste - måske er du
      offline?
    </div>
    <div class="mb-2" id="gamificationTitle">
      <h4>{{ $englishPreferred ? "Game mode" : "Spiltilstand" }}</h4>
    </div>
    <div id="gamificationDiv" class="mb-4">
      <div class="form-check form-switch">
        <input
          v-model="rankingActive"
          @change="rankingActivityToggle()"
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
        />
        <label class="form-check-label" for="flexSwitchCheckDefault">{{ $englishPreferred ? "Show me on rankings" : "Vis mig på ranglister" }}</label
        >
      </div>
      <div class="form-check form-switch">
        <input
          v-model="seePoints"
          @change="checkPoints($event)"
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked"
          checked
        />
        <label class="form-check-label" for="flexSwitchCheckChecked"
          >{{ $englishPreferred ? "Show points on my personal statistics" : "Vis point på min personlige statistikside" }}</label
        >
      </div>
    </div>

    <div id="langaugeTitle" class="mb-2">
      <h4>{{ $englishPreferred ? "Language" : "Sprog" }}</h4>
    </div>
    <span>{{ $englishPreferred ? "Here you may switch between Danish and English as language" : "Her kan du skifte mellem dansk eller engelsk sprog i appen" }}:</span>
    <div id="languageDiv" class="mb-4">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          v-model="englishPreferred"
          v-bind:value="false"
          @change="switchToEnglish(false)"
          name="languageOptions"
          id="danishRadio"
          checked
        />
        <label class="form-check-label" for="danishRadio">{{ $englishPreferred ? "Danish" : "Dansk" }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          v-model="englishPreferred"
          v-bind:value="true"
          @change="switchToEnglish(true)"
          name="languageOptions"
          id="englishRadio"
        />
        <label class="form-check-label" for="englishRadio">{{ $englishPreferred ? "English" : "Engelsk" }}</label>
      </div>
    </div>

    <div id="langaugeTitle" class="mb-2">
      <h4>{{ $englishPreferred ? "Map background" : "Kortbaggrund" }}</h4>
    </div>
    <span>{{ $englishPreferred ? "Here you may switch the default map background" : "Her kan du skifte den standard kortbaggrund" }}:</span>
    <div id="languageDiv">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          v-model="ortoPreferred"
          v-bind:value="false"
          @change="switchToOrto(false)"
          name="mapOptions"
          id="openStreetMap"
          checked
        />
        <label class="form-check-label" for="openStreetMap"
          >OpenStreetMap</label
        >
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          v-model="ortoPreferred"
          v-bind:value="true"
          @change="switchToOrto(true)"
          name="mapOptions"
          id="ortofotoMap"
        />
        <label class="form-check-label" for="ortofotoMap">Ortofoto</label>
      </div>
    </div>
  </div>
  <br />
</template>

<script>
import NavHeader from "@/components/navbars/NavHeader.vue";
import axios from "axios";

export default {
  components: {
    NavHeader,
  },
  data() {
    return {
      title: this.$englishPreferred ? "Settings" : "Indstillinger",
      seePoints: true,
      ortoPreferred: false,
      englishPreferred: false,
      rankingActive: true,
      couldNotUpdateRankingSetting: false,
    };
  },
  methods: {
    checkPoints: function (e) {
      let value = this.seePoints ? true : false;
      $cookies.set("seePoints", value, 60 * 60 * 24 * 365 * 10);
    },
    switchToEnglish(booleanValue) {
      if (booleanValue == true) {
        $cookies.set("englishPreferred", 60 * 60 * 24 * 365 * 10);
      } else {
        $cookies.remove("englishPreferred");
      }
      window.location.reload();
    },
    switchToOrto(booleanValue) {
      if (booleanValue == true) {
        $cookies.set("ortoPreferred", 60 * 60 * 24 * 365 * 10);
      } else {
        $cookies.remove("ortoPreferred");
      }
    },
    async rankingActivityToggle() {
      let isOptingOut = this.rankingActive ? false : true;
      if (isOptingOut == true) {
        axios
          .get("/spontan/ranking/optout")
          .then((response) => {
            $cookies.set("rankingInactive", 60 * 60 * 24 * 365 * 10);
            this.couldNotUpdateRankingSetting = false;
          })
          .catch((error) => {
            this.rankingActive = true;
            this.couldNotUpdateRankingSetting = true;
          });
      } else {
        axios
          .get("/spontan/ranking/optin")
          .then((response) => {
            $cookies.remove("rankingInactive");
            this.couldNotUpdateRankingSetting = false;
          })
          .catch((error) => {
            this.rankingActive = false;
            this.couldNotUpdateRankingSetting = true;
          });
      }
    },
  },
  mounted() {
    if ($cookies.isKey("seePoints")) {
      this.seePoints = $cookies.get("seePoints");
    }
    if ($cookies.isKey("ortoPreferred")) {
      this.ortoPreferred = true;
    }
    if ($cookies.isKey("englishPreferred")) {
      this.englishPreferred = true;
    }
    if ($cookies.isKey("rankingInactive")) {
      this.rankingActive = false;
    }
  },
};
</script>
